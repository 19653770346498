import React from 'react';
import PropTypes from 'prop-types';

import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
  FormLabel,
} from '@material-ui/core';

function GridedFormCheckbox({ checked, onChange, value }) {
  return (
    <Grid item xs={6}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={(e) => onChange(e.target.checked)}
            value={value}
            color="primary"
            id={`filter-dialog-${value.split(' ').join('-')}`}
          />
        }
        label={value}
      />
    </Grid>
  );
}
GridedFormCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

function FilterDialog({
  open,
  setOpen,
  onSubmit,
  pendingOnAccManager,
  pendingOnApprover,
  regulated,
  delisted,
  unknown,
}) {
  const onClickClearAll = () => {
    pendingOnAccManager.set(false);
    pendingOnApprover.set(false);
    regulated.set(false);
    delisted.set(false);
    unknown.set(false);
  };
  return (
    <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Filter</DialogTitle>
      <DialogContent>
        <form method="post" onSubmit={onSubmit}>
          <Grid container direction="column" spacing={1}>
            <FormLabel>VASP Status</FormLabel>
            <Grid container item direction="row" spacing={1}>
              <GridedFormCheckbox
                checked={pendingOnAccManager.get}
                onChange={pendingOnAccManager.set}
                value="pending on account manager"
              />
              <GridedFormCheckbox
                checked={pendingOnApprover.get}
                onChange={pendingOnApprover.set}
                value="pending on approver"
              />
              <GridedFormCheckbox
                checked={regulated.get}
                onChange={regulated.set}
                value="regulated"
              />
              <GridedFormCheckbox checked={delisted.get} onChange={delisted.set} value="delisted" />
              <GridedFormCheckbox checked={unknown.get} onChange={unknown.set} value="unknown" />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickClearAll} id="filter-dialog-clear-all">
          Clear all
        </Button>
        <Button onClick={() => setOpen(false)} id="filter-dialog-cancel-btn">
          Cancel
        </Button>
        <Button onClick={onSubmit} id="filter-dialog-apply-btn">
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}

FilterDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  pendingOnAccManager: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  pendingOnApprover: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  delisted: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  regulated: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  unknown: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
};

export default FilterDialog;
