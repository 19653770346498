import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, IconButton, TableBody, TableRow, TableCell, Checkbox } from '@material-ui/core';
import { Edit, Visibility } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import { VaspRegulationStatus, RoleId } from '../../../constants';
import { getToken, getRoleId } from '../../../utils/utils';

const calEmptyRows = (rows, page, rowsPerPage) =>
  rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

const renderEmptyTableBody = (headCells) => (
  <TableBody>
    <TableRow style={{ height: 300 }} key={`empty-${0}`}>
      <TableCell
        style={{
          paddingTop: 0,
          paddingBottom: 0,
          textAlign: 'center',
        }}
        colSpan={headCells.length + 1}
        width="100%"
        key="empty-"
      >
        No records to display
      </TableCell>
    </TableRow>
  </TableBody>
);

const renderActionIcons = (row, roleId, onEditActionClick, index) => {
  const isEditable =
    (row.status === VaspRegulationStatus.PendingOnAcc && roleId === RoleId.ACCOUNT_MANAGER) ||
    (row.status === VaspRegulationStatus.PendingOnApprover && roleId === RoleId.ADMIN);
  if (!isEditable) {
    return (
      <Tooltip title="visibility">
        <IconButton
          id={`ActionIcon_${index}`}
          onClick={() => {
            onEditActionClick(row);
          }}
        >
          <Visibility />
        </IconButton>
      </Tooltip>
    );
  }
  return (
    <Tooltip title="edit">
      <IconButton
        id={`ActionIcon_${index}`}
        onClick={() => {
          onEditActionClick(row);
        }}
      >
        <Edit />
      </IconButton>
    </Tooltip>
  );
};

const renderSkeletonTableBody = () => (
  <TableBody>
    {[0, 1, 2, 3, 4].map((index) => (
      <TableRow key={`empty-${index}`}>
        <TableCell>
          <Checkbox disabled checked={false} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
      </TableRow>
    ))}
  </TableBody>
);

const renderDataTableBody = (data) => {
  const { rows, page, rowsPerPage, onEditActionClick, headCells, roleId } = data;
  const emptyRows = calEmptyRows(rows, page, rowsPerPage);

  return (
    <TableBody>
      {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
        const labelId = `regulation-table-checkbox-${index}`;
        return (
          <TableRow hover key={row.id}>
            <TableCell component="th" id={labelId} scope="row" width={headCells[0].width}>
              {renderActionIcons(row, roleId, onEditActionClick, index)}
            </TableCell>

            <TableCell align="left" width={headCells[1].width}>
              {row.vasp_code}
            </TableCell>
            <TableCell align="left" width={headCells[2].width}>
              {row.vasp_name}
            </TableCell>
            <TableCell
              align="left"
              width={headCells[3].width}
              style={{
                whiteSpace: 'normal',
                wordWrap: 'break-word',
              }}
              id={`regulation-table-status-${index}`}
            >
              {row.status}
            </TableCell>
            <TableCell
              align="left"
              width={headCells[4].width}
              id={`regulation-table-regulation-status-${index}`}
            >
              {row.data ? row.data.regulatoryStatus : ''}
            </TableCell>
          </TableRow>
        );
      })}
      {emptyRows > 0 && (
        <TableRow style={{ height: 53 * emptyRows }}>
          <TableCell width="100%" colSpan={headCells.length} />
        </TableRow>
      )}
    </TableBody>
  );
};

const RegulationTableBody = (props) => {
  const { rows, loading } = props;
  if (loading) {
    return renderSkeletonTableBody();
  }
  if (rows.length === 0) {
    return renderEmptyTableBody(props.headCells);
  }
  const token = getToken();
  const roleId = getRoleId(token);
  return renderDataTableBody({ ...props, roleId });
};

RegulationTableBody.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      operation: PropTypes.string,
      vasp_code_prefix: PropTypes.string,
      country_code: PropTypes.string,
      city_code: PropTypes.string,
      vasp_name: PropTypes.string,
      go_live_dt: PropTypes.string,
      vasp_status: PropTypes.string,
      checkable: PropTypes.bool,
      vasp_code: PropTypes.string,
    }),
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  isItemSelected: PropTypes.func.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  onEditActionClick: PropTypes.func.isRequired,
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      numeric: PropTypes.bool,
      label: PropTypes.string,
      disablePadding: PropTypes.bool,
      width: PropTypes.string,
    }),
  ).isRequired,
};
export default RegulationTableBody;
