/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Paper, Table, TableContainer, TablePagination } from '@material-ui/core';
import RegultaionTableToolbar from './RegulationTableToolbar';
import RegulationTableHead from './RegulationTableHead';
import RegulationTableBody from './RegulationTableBody';
import { useStyles, useToolbarStyles } from './styles';
import FilterDisplayer from '../filter-displayer';

function RegulationTable(props) {
  const {
    selectedVaspIds,
    regulationList,
    onSelectionChange,
    onEditActionClick,
    searchText,
    setFilterPanelOpen,
    pendingOnAccManager,
    pendingOnApprover,
    regulated,
    delisted,
    unknown,
    loading,
  } = props;

  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isItemSelected = (id) => selectedVaspIds.indexOf(id) !== -1;

  const validateData = regulationList.filter((data) => data.checkable);

  const genHeadCells = () => [
    { id: 'icons', numeric: false, label: '', disablePadding: false, width: '5%' },
    { id: 'vasp_code', numeric: false, label: 'VASP code', disablePadding: false, width: '15%' },
    { id: 'vasp_name', numeric: false, label: 'VASP name', disablePadding: false, width: '30%' },
    { id: 'status', numeric: false, label: 'Status', disablePadding: false, width: '25%' },
    {
      id: 'regulation_status',
      numeric: false,
      label: 'Regulation Status',
      disablePadding: false,
      width: '25%',
    },
  ];

  const headCells = genHeadCells();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <RegultaionTableToolbar
          classes={useToolbarStyles()}
          numSelected={selectedVaspIds.length}
          searchText={searchText}
        />

        <FilterDisplayer
          onOpenFilterClicked={() => setFilterPanelOpen(true)}
          pendingOnAccManager={{ set: pendingOnAccManager.set, get: pendingOnAccManager.get }}
          pendingOnApprover={{ set: pendingOnApprover.set, get: pendingOnApprover.get }}
          regulated={{ set: regulated.set, get: regulated.get }}
          delisted={{ set: delisted.set, get: delisted.get }}
          unknown={{ set: unknown.set, get: unknown.get }}
        />

        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="approve table"
          >
            <RegulationTableHead
              headCells={headCells}
              classes={classes}
              numSelected={selectedVaspIds.length}
              rowCount={validateData.length}
            />
            <RegulationTableBody
              headCells={headCells}
              rows={regulationList}
              page={page}
              rowsPerPage={rowsPerPage}
              isItemSelected={isItemSelected}
              onSelectionChange={onSelectionChange}
              onEditActionClick={onEditActionClick}
              loading={loading}
            />
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={regulationList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

RegulationTable.propTypes = {
  selectedVaspIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  regulationList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      operation: PropTypes.string,
      vasp_code_prefix: PropTypes.string,
      country_code: PropTypes.string,
      city_code: PropTypes.string,
      vasp_name: PropTypes.string,
      go_live_dt: PropTypes.string,
      vasp_status: PropTypes.string,
      checkable: PropTypes.bool,
      vasp_code: PropTypes.string,
    }),
  ).isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  onEditActionClick: PropTypes.func.isRequired,
  searchText: PropTypes.shape({
    get: PropTypes.string,
    set: PropTypes.func,
  }).isRequired,
  setFilterPanelOpen: PropTypes.func.isRequired,
  pendingOnAccManager: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  pendingOnApprover: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  regulated: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  delisted: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  unknown: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default RegulationTable;
