import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLazyQuery } from '@apollo/react-hooks';
import * as l from 'lodash';
import Layout from '../components/layout';
import Navigator from '../components/navigator';
import { ErrorDialog } from '../components/common';
import AuthenticatedPage from '../components/authenticated-page';
import {
  navigate,
  checkJWTExpired,
  parseGraphQLErrorMessage,
  getToken,
  getRoleId,
} from '../utils/utils';

import { RegulationTable, FilterDialog } from '../components/regulation-list';
import { GET_VASP_REGULATIONS } from '../crud';
import { VaspRegulationStatus, RoleId } from '../constants';

function RegulationList() {
  const token = getToken();
  const roleId = getRoleId(token);

  const [regulationList, setRegulationList] = useState([]);
  const [selectedVaspIds, setSelectedVaspIds] = useState([]);
  const [searchText, setSearchText] = useState('');

  const [filterPanelOpen, setFilterPanelOpen] = useState(false);

  // VASP status in backoffice
  const [pendingOnAccManager, setPendingOnAccManager] = useState(roleId === RoleId.ACCOUNT_MANAGER);
  const [pendingOnApprover, setPendingOnApprover] = useState(roleId === RoleId.ADMIN);
  const [regulated, setRegulated] = useState(false);
  const [unknown, setUnknown] = useState(false);
  const [delisted, setDelisted] = useState(false);

  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [error, setError] = useState('');

  const [getRegulationList, getRegulationListResult] = useLazyQuery(GET_VASP_REGULATIONS, {
    context: {
      headers: {
        authorization: token,
      },
    },
    onCompleted: (data) => {
      if (!data) {
        setErrorDialogOpen(true);
        setError(parseGraphQLErrorMessage('data is undefined'));
        return;
      }
      if (l.get(data, 'getVaspRegulations')) {
        setRegulationList(l.get(data, 'getVaspRegulations'));
      } else {
        setRegulationList([]);
      }
    },
    onError: (e) => {
      setErrorDialogOpen(true);
      setError(parseGraphQLErrorMessage(e));
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (token && !checkJWTExpired(token)) {
      getRegulationList();
    }
  }, []);

  const handleSelectAllClick = (event, tempVaspData) => {
    if (event.target.checked) {
      const newSelectedIds = tempVaspData.filter((data) => data.checkable).map((data) => data.id);
      setSelectedVaspIds(newSelectedIds);
      return;
    }
    setSelectedVaspIds([]);
  };

  const handleDeleteSelectionClick = () => {
    setSelectedVaspIds([]);
  };

  const handleEditActionClick = (rowData) => {
    const parameter = { vaspCode: rowData.vasp_code };
    navigate('/vasp-regulation', { state: { parameter } });
  };

  const handleSelectionChange = (_, selectedId) => {
    const selectedIndex = selectedVaspIds.indexOf(selectedId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedVaspIds, selectedId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedVaspIds.slice(1));
    } else if (selectedIndex === selectedVaspIds.length - 1) {
      newSelected = newSelected.concat(selectedVaspIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedVaspIds.slice(0, selectedIndex),
        selectedVaspIds.slice(selectedIndex + 1),
      );
    }
    setSelectedVaspIds(newSelected);
  };

  const handleSubmit = (e) => {
    setFilterPanelOpen(false);
    e.preventDefault();
  };

  const filterSearchText = (o) => {
    if (!searchText || searchText.length === 0) {
      return true;
    }
    return `${o.vasp_code},${o.vasp_name}`.toLowerCase().includes(searchText.toLowerCase());
  };

  let tempregulationList = l.filter(regulationList || [], filterSearchText);
  tempregulationList = l.filter(
    tempregulationList,
    (o) =>
      // if non selected, show all
      (!pendingOnAccManager && !pendingOnApprover && !regulated && !delisted && !unknown) ||
      (pendingOnAccManager && o.status === VaspRegulationStatus.PendingOnAcc) ||
      (pendingOnApprover && o.status === VaspRegulationStatus.PendingOnApprover) ||
      (regulated && o.status === VaspRegulationStatus.Regulated) ||
      (delisted && o.status === VaspRegulationStatus.Delisted) ||
      (unknown && o.status === VaspRegulationStatus.Unknown),
  );

  return (
    <Layout>
      <AuthenticatedPage>
        <div className="application">
          <Helmet>
            <meta charSet="utf-8" />
            <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
          </Helmet>
        </div>
        <Navigator
          breadcrumbTexts={['Home', 'VASP Regulation']}
          breadcrumbLinks={['/', '/vasp-regulation-list']}
        />
        <div style={{ maxWidth: '100%', marginTop: '2em' }}>
          <RegulationTable
            selectedVaspIds={selectedVaspIds}
            regulationList={tempregulationList || []}
            onEditActionClick={handleEditActionClick}
            onSelectAllClick={(e) => {
              handleSelectAllClick(e, tempregulationList);
            }}
            onSelectionChange={handleSelectionChange}
            onDeleteSelectionClick={handleDeleteSelectionClick}
            searchText={{ get: searchText, set: setSearchText }}
            setFilterPanelOpen={setFilterPanelOpen}
            pendingOnAccManager={{ set: setPendingOnAccManager, get: pendingOnAccManager }}
            pendingOnApprover={{ set: setPendingOnApprover, get: pendingOnApprover }}
            delisted={{ set: setDelisted, get: delisted }}
            regulated={{ set: setRegulated, get: regulated }}
            unknown={{ set: setUnknown, get: unknown }}
            loading={getRegulationListResult.loading}
          />
        </div>

        <FilterDialog
          open={filterPanelOpen}
          setOpen={setFilterPanelOpen}
          onSubmit={handleSubmit}
          pendingOnAccManager={{ set: setPendingOnAccManager, get: pendingOnAccManager }}
          pendingOnApprover={{ set: setPendingOnApprover, get: pendingOnApprover }}
          delisted={{ set: setDelisted, get: delisted }}
          regulated={{ set: setRegulated, get: regulated }}
          unknown={{ set: setUnknown, get: unknown }}
        />

        <ErrorDialog open={{ get: errorDialogOpen, set: setErrorDialogOpen }} message={error} />
      </AuthenticatedPage>
    </Layout>
  );
}

export default RegulationList;
