import React from 'react';
import PropTypes from 'prop-types';

import { Paper, Grid, IconButton, Chip, Tooltip } from '@material-ui/core';

import { FilterList as FilterIcon, CheckBox as CheckBoxIcon } from '@material-ui/icons';

function FilterDisplayer({
  onOpenFilterClicked,
  pendingOnAccManager,
  pendingOnApprover,
  regulated,
  delisted,
  unknown,
}) {
  return (
    <Paper>
      <Grid container item spacing={1} alignItems="center">
        <Grid item>
          <Tooltip title="filter">
            <IconButton onClick={onOpenFilterClicked} id="filter_button">
              <FilterIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid
          item
          id="pending on account manager"
          style={{ display: pendingOnAccManager.get ? 'inline-flex' : 'none' }}
        >
          <Chip
            icon={<CheckBoxIcon />}
            label="pending on account manager"
            onDelete={() => pendingOnAccManager.set(false)}
          />
        </Grid>
        <Grid
          item
          id="pending on approver"
          style={{ display: pendingOnApprover.get ? 'inline-flex' : 'none' }}
        >
          <Chip
            icon={<CheckBoxIcon />}
            label="pending on approver"
            onDelete={() => pendingOnApprover.set(false)}
          />
        </Grid>

        <Grid item id="regulated" style={{ display: regulated.get ? 'inline-flex' : 'none' }}>
          <Chip icon={<CheckBoxIcon />} label="regulated" onDelete={() => regulated.set(false)} />
        </Grid>
        <Grid item id="delisted" style={{ display: delisted.get ? 'inline-flex' : 'none' }}>
          <Chip icon={<CheckBoxIcon />} label="delisted" onDelete={() => delisted.set(false)} />
        </Grid>
        <Grid item id="unknown" style={{ display: unknown.get ? 'inline-flex' : 'none' }}>
          <Chip icon={<CheckBoxIcon />} label="unknown" onDelete={() => unknown.set(false)} />
        </Grid>
      </Grid>
    </Paper>
  );
}

FilterDisplayer.propTypes = {
  onOpenFilterClicked: PropTypes.func.isRequired,
  pendingOnAccManager: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  pendingOnApprover: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  regulated: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  delisted: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  unknown: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
};

export default FilterDisplayer;
